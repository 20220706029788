// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"

import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"


Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'bootstrap'
global.toastr = require("toastr")

import flatpickr from 'flatpickr'
import { Italian } from "flatpickr/dist/l10n/it.js"


import { addAttribute } from './addattribute'
import { addDestination } from './adddestination'
import { removeDestination } from './removedestination'
import { addContact } from './addcontact'


document.addEventListener('turbolinks:load', ()=> {
  if (document.querySelector('#fieldsetContainer')) {
    addAttribute()
  }
  if (document.querySelector('#nestedDestination')) {
    addDestination()
  }
  if (document.querySelector('#nestedDestination')) {
    removeDestination()
  }
  if (document.querySelector('#nestedContacts')) {
    addContact()
  }

})

document.addEventListener('turbolinks:load', ()=> {
  flatpickr('.mydate', {
    locale: Italian,
    dateFormat: "d-m-Y",
    allowInput: true
  })
  
})



// Funzione per aggiungere i gestori di eventi ai link WhatsApp
function addWhatsAppGenericLinkEventHandlers() {
  // Otteniamo tutti i link con classe 'whatsapp-link-generic'
  const whatsappLinks = document.querySelectorAll('.whatsapp-link-generic');

  // Iteriamo su ogni link
  whatsappLinks.forEach(function(link) {
      // Aggiungiamo un gestore di eventi al click
      link.addEventListener('click', function(event) {
          // Impediamo il comportamento predefinito del link (aprire una nuova pagina)
          //event.preventDefault();
          
          // Otteniamo il testo contenuto nel link
          const linkText = link.textContent;
          
          // Selezioniamo il genitore della cella (il td)
          const parentTd = link.parentNode;
          
          // Rimuoviamo il link dal genitore
          parentTd.removeChild(link);
          
          // Inseriamo il testo senza il link nel genitore
          parentTd.textContent = linkText;
          
          // Aggiungiamo una classe 'alert-generic-send-td' al genitore
          parentTd.classList.add('alert-generic-send-td');
      });
  });
}

// Ascolta l'evento turbolinks:load che viene emesso quando Turbolinks carica una nuova pagina
document.addEventListener('turbolinks:load', function() {
  addWhatsAppGenericLinkEventHandlers();
});



// Funzione per aggiungere i gestori di eventi ai link WhatsApp
function addWhatsAppLinkEventHandlers() {
  // Otteniamo tutti i link con classe 'whatsapp-link-five-days'
  const whatsappLinks = document.querySelectorAll('.whatsapp-link-five-days');

  // Iteriamo su ogni link
  whatsappLinks.forEach(function(link) {
      // Aggiungiamo un gestore di eventi al click
      link.addEventListener('click', function(event) {
          // Impediamo il comportamento predefinito del link (aprire una nuova pagina)
          //event.preventDefault();
          
          // Otteniamo il testo contenuto nel link
          const linkText = link.textContent;
          
          // Selezioniamo il genitore della cella (il td)
          const parentTd = link.parentNode;
          
          // Rimuoviamo il link dal genitore
          parentTd.removeChild(link);
          
          // Inseriamo il testo senza il link nel genitore
          parentTd.textContent = linkText;
          
          // Aggiungiamo una classe 'alert-generic-send-td' al genitore
          parentTd.classList.add('alert-send-td');
      });
  });
}

document.addEventListener('turbolinks:load', function () {
  const currentPageIdentifier = window.location.pathname;

  if (currentPageIdentifier === "/manage_multiple_expiration") {
    const form = document.querySelector('#mioForm');
    const selectedRecordIdsField = document.querySelector('input[name="selected_policy_data_ids"]');
    const submitButton = form.querySelector('input[type="submit"]');

    // Funzione per ottenere le selezioni dalla localStorage
    function getSelectionFromStorage() {
      const storedSelections = JSON.parse(localStorage.getItem('userSelections')) || {};
      return storedSelections[currentPageIdentifier] || [];
    }  

    // Funzione per aggiornare le selezioni nella localStorage
    function updateSelectionInStorage(selectedRecordIds) {
      let storedSelections = JSON.parse(localStorage.getItem('userSelections')) || {};
      const currentPageSelections = storedSelections[currentPageIdentifier] || [];
      
      // Rimuovi gli ID duplicati dall'array delle selezioni aggiornate
      const updatedSelections = [...new Set([...currentPageSelections, ...selectedRecordIds])];

      storedSelections[currentPageIdentifier] = updatedSelections;
      localStorage.setItem('userSelections', JSON.stringify(storedSelections));

      // Aggiorna il pulsante di submit
      updateSubmitButtonState();
    }

    // Funzione per verificare e aggiornare lo stato del pulsante di submit
    function updateSubmitButtonState() {
      const storedSelections = JSON.parse(localStorage.getItem('userSelections')) || {};
      const currentPageSelections = storedSelections[currentPageIdentifier] || [];
      submitButton.disabled = currentPageSelections.length === 0;
    }

    // Ripristina le selezioni all'avvio della pagina
    const currentPageSelections = getSelectionFromStorage();
    const userCheckboxes = document.querySelectorAll('input[name="policy_datum_selected[]"]');
    userCheckboxes.forEach(function (checkbox) {
      checkbox.checked = currentPageSelections.includes(checkbox.value);
    });

    // Aggiorna le selezioni quando cambia la pagina
    userCheckboxes.forEach(function (checkbox) {
      checkbox.addEventListener('change', function () {
        const selectedRecordIds = [];
        userCheckboxes.forEach(function (checkbox) {
          if (checkbox.checked) {
            selectedRecordIds.push(checkbox.value);
          }
        });
        updateSelectionInStorage(selectedRecordIds);

        // Rimuovi l'ID dalla localStorage se il record viene deselezionato
        if (!checkbox.checked) {
          const storedSelections = JSON.parse(localStorage.getItem('userSelections')) || {};
          const currentPageSelections = storedSelections[currentPageIdentifier] || [];
          const indexToRemove = currentPageSelections.indexOf(checkbox.value);
          if (indexToRemove !== -1) {
            currentPageSelections.splice(indexToRemove, 1);
            storedSelections[currentPageIdentifier] = currentPageSelections;
            localStorage.setItem('userSelections', JSON.stringify(storedSelections));
          }
          
          // Aggiorna il pulsante di submit
          updateSubmitButtonState();

        }
      });
    });

    // Aggiorna il campo nascosto con gli ID degli utenti selezionati prima di inviare il modulo
    form.addEventListener('submit', function (event) {
      const storedSelections = JSON.parse(localStorage.getItem('userSelections')) || {};
      const allSelectedRecordIds = storedSelections[currentPageIdentifier] || [];
      selectedRecordIdsField.value = JSON.stringify(allSelectedRecordIds);
    });

    // Inizializza lo stato del pulsante di submit
    updateSubmitButtonState();
  } else {
    // Se non sei sulla pagina desiderata, rimuovi l'oggetto userSelections dalla localStorage
    if (localStorage.getItem('userSelections')) {
      localStorage.removeItem('userSelections');
    }
  }
});
