const addContact = ()=> {
    const createButton = document.getElementById('addContact');
      
    createButton.addEventListener('click', () => {
  
      const lastId = document.querySelector("#nestedContacts").lastElementChild.id;
      
      const newId = parseInt(lastId, 10) + 1;
      
      const newFieldset = document.querySelector('[id="0"]').outerHTML.replace(/0/g,newId);
      
      //---------------------------------------------------------------------- per eliminare contenuto
      const obj = new DOMParser();
      const originalElelemnt = obj.parseFromString(newFieldset, 'text/html');
      const element_type = originalElelemnt.querySelector('#setcontacttype'+newId);
      element_type.setAttribute('value',"");
      const element_value = originalElelemnt.querySelector('#setcontactvalue'+newId);
      element_value.setAttribute('value',"");
      let childErase = originalElelemnt.getElementById('row'+newId);
      childErase = childErase.removeChild(childErase.lastElementChild);
      const emptyElement = element_value.parentElement.parentElement.parentElement.outerHTML;
      //---------------------------------------------------------------------------------------------
  
      document.querySelector("#nestedContacts").insertAdjacentHTML(
          "beforeend", emptyElement
      );
    });
    }
    export { addContact }