const addAttribute = ()=> {
  const createButton = document.getElementById('addAttribute');
    
  createButton.addEventListener('click', () => {

    const lastId = document.querySelector("#fieldsetContainer").lastElementChild.id;
    
    const newId = parseInt(lastId, 10) + 1;
    
    const newFieldset = document.querySelector('[id="0"]').outerHTML.replace(/0/g,newId);
    
    //---------------------------------------------------------------------- per eliminare contenuto
    const obj = new DOMParser();
    const originalElelemnt = obj.parseFromString(newFieldset, 'text/html');
    const element = originalElelemnt.querySelector('#setvalue'+newId);
    element.setAttribute('value',"");
    let childErase = originalElelemnt.getElementById('row'+newId);
    childErase = childErase.removeChild(childErase.lastElementChild);
    const emptyElement = element.parentElement.parentElement.parentElement.outerHTML;
    //---------------------------------------------------------------------------------------------

    document.querySelector("#fieldsetContainer").insertAdjacentHTML(
        "beforeend", emptyElement
    );
  });
  }
  export { addAttribute }