const removeDestination = ()=> {
    const createButton = document.getElementById('removeDestination');
      
    createButton.addEventListener('click', () => {
  
      const nestedDiv = document.querySelector("#nestedDestination");
      
      nestedDiv.style.visibility = 'hidden';

      const nestednameDestination = document.querySelector("#nested_name_destination");
      nestednameDestination.value = "";
      nestednameDestination.disabled = true;

      const nestedcheckboxDestination = document.querySelector("#nested_checkbox_destination");
      nestedcheckboxDestination.checked = false;
      nestedcheckboxDestination.disabled = true;

      const selectDestination = document.querySelector("#select_destination");
      
      selectDestination.disabled = false;

    });
    }
  export { removeDestination }