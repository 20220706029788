const addDestination = ()=> {
    const createButton = document.getElementById('addDestination');
      
    createButton.addEventListener('click', () => {
  
      const nestedDiv = document.querySelector("#nestedDestination");
      
      nestedDiv.style.visibility = 'visible';

      const nestednameDestination = document.querySelector("#nested_name_destination");
      
      nestednameDestination.disabled = false;

      const nestedcheckboxDestination = document.querySelector("#nested_checkbox_destination");
      
      nestedcheckboxDestination.disabled = false;

      const selectDestination = document.querySelector("#select_destination");
      
      selectDestination.disabled = true;

    });
    }
  export { addDestination }